
.navbar {
    display: flex;
    box-shadow: rgba(0, 0, 0, .15) 0px -2px 10px 0px;
    justify-content: space-between;
    align-items: center;
    padding: .5em 2em;
}

.brand span {

    font-weight: 900;
    text-transform: uppercase;

}


.links_list {

    display: flex;
    list-style: none;

}

.links_list {
    margin-right: 1em;
}

.links_list li a {

    padding: .4em .6em;

}

.active {

    background-color: #000;
    color: #fff;

}